<template>
  <div class="app-container">
    <el-row :gutter="24">
      <el-col :span="24" v-if="type == 'edit'">
        <el-card style="margin-bottom: 10px">
          距离考试结束还有：<span style="color: #ff0000;">{{ hour }}小时{{ minute }}分钟{{ second }}秒</span>
        </el-card>
      </el-col>
      <el-col :span="6" :xs="24" style="margin-bottom: 10px">
        <el-card style="height: calc(100vh - 162px);">
          <p class="card-title">答题卡</p>
          <el-row :gutter="24" class="card-line" style="padding-left: 10px">
            <el-tag type="info">未作答</el-tag>
            <el-tag type="success">已作答</el-tag>
          </el-row>
          <div v-for="(sortItem,sortIndex) in paperSortData" :key="sortIndex">
            <div
              v-if="sortItem.sort != -1 && paperData[sortItem.name]!==undefined && paperData[sortItem.name].length > 0">
              <p class="card-title">{{ sortItem.title }}</p>
              <el-row :gutter="24" class="card-line">
                <el-tag
                  v-for="item in paperData[sortItem.name]"
                  :key="item.label"
                  :type="cardItemClass(item.answered, item.id, item.answer)"
                  @click="handSave(item)"
                > {{ item.sort + 1 }}
                </el-tag>
              </el-row>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="18" :xs="24">
        <el-card class="qu-content">
          <p v-if="quData.content">{{ quData.sort + 1 }}.{{ quData.content }}</p>
          <div v-if="quData.quType === '1' || quData.quType==='3'">
            <el-radio-group v-model="radioValue" :disabled="type == 'view'">
              <el-radio v-for="(item,index) in quData.answerList" :key="index" :label="item.id">{{
                  item.answerOption
                }}.{{ item.answerOptionValue }}
              </el-radio>
            </el-radio-group>
          </div>
          <div v-if="quData.quType === '2'">
            <el-checkbox-group v-model="multiValue" :disabled="type == 'view'">
              <el-checkbox v-for="(item,index) in quData.answerList" :key="index" :label="item.id">{{
                  item.answerOption
                }}.{{ item.answerOptionValue }}
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </el-card>
        <el-card style="margin-top: 20px;color: #f74e57" v-if="type == 'view'">
          <p>正确答案：{{ quData.correctAnswer }}</p>
          <p>答案解析：{{ quData.analysis }}</p>
        </el-card>
        <div style="margin-top: 20px">
          <el-button v-if="showPrevious" type="primary" icon="el-icon-arrow-left" @click="handPrevious()">上一题
          </el-button>
          <el-button v-if="showNext" type="warning" @click="handNext()">下一题<i
            class="el-icon-arrow-right el-icon--right"></i></el-button>
        </div>
      </el-col>
    </el-row>
    <div class="fixed_button" v-if="type == 'edit'">
      <el-button style="float: right; margin-top: -10px" type="primary" icon="el-icon-plus" :loading="loading"
                 @click="handHandExam()">
        {{ handleText }}
      </el-button>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="livrerShow"
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :center="true"
      width="30%">
      <div style="text-align: center;font-size: 26px;font-weight: bold;color: #333333">考试时间已到，请交卷</div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handHandExam">交 卷</el-button>
      </span>
    </el-dialog>
  </div>

</template>

<script>
import {getExamPaper, saveEndTime, updateEndTime, userAnswerSave} from "@/api/training/edupaperquestion";
import {getAnswers} from "@/api/training/eduquestions";
import {update} from "@/api/training/eduuserexam";
import {trainingTaskUseStatusUpdate} from "@/api/safe/index";
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      // 因为答题后传给后端的数据出现了错位， 所以加入该变量记录上一个题目的信息
      lastQuestion: undefined,
      // 全屏/不全屏
      isFullscreen: false,
      showPrevious: false,
      showNext: true,
      loading: false,
      handleText: '交卷',
      livrerShow: false,
      pageLoading: false,
      // 培训id
      trainingId: '',
      // 试卷ID
      paperId: '',
      examId: '',
      // 当前答题卡
      cardItem: {},
      allItem: [],
      // 当前题目内容
      quData: {
        answerList: []
      },
      paperSortData: [
        {
          title: "单选题",
          name: "radioList",
          sort: -1,
        },
        {
          title: "多选题",
          name: "multiList",
          sort: -1,
        },
        {
          title: "判断题",
          name: "judgeList",
          sort: -1,
        }
      ],
      // 试卷信息
      paperData: {
        answerTime: '',
        radioList: [],
        multiList: [],
        judgeList: [],
        startTime: "",
        endTime: ""
      },
      // 单选选定值
      radioValue: '',
      // 多选选定值
      multiValue: [],
      // 已答ID
      answeredIds: [],
      eduUserExamDetailLists: [],
      form: {},
      hour: 0,
      minute: 0,
      second: 0,
      type: ''
    }
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  created() {
    if (this.$route.query.row) {
      this.form.startTime = this.formatDate(new Date())
      let row = JSON.parse(this.$route.query.row);
      this.paperId = row.paperId;
      this.trainingId = row.trainingId
      this.examId = row.examId;
      if (row.id) {
        this.form.id = row.id
      }
      if (row.subjectType) {
        this.form.subjectType = row.subjectType
      }
      if (row.paperName) {
        this.form.paperName = row.paperName
      }
      if (row.type) {
        this.type = row.type;
      }
      this.fetchData(this.paperId)
    }
  },
  watch: {
    second() {
    }
  },
  destroyed() {
    let totalMinutes = this.timeToMinutes(this.hour, this.minute, this.second);
    updateEndTime({
      endTime: totalMinutes,
      taskId: this.form.id
    })
    let data = {
      eduUserExamDetailList: this.eduUserExamDetailLists,
      id: this.form.id
    }
    // userAnswerSave(data)
  },
  methods: {
    // 将时分秒转换成分钟数
    timeToMinutes(hours, minutes, seconds) {
      const totalMinutes = hours * 60 + minutes + seconds / 60;
      return totalMinutes.toFixed(2);
    },
    countdown() {
      let time = '';
      if (this.paperData.endTime) {
        time = this.paperData.endTime
      } else {
        time = this.paperData.answerTime
      }
      const answerTime = Number(60) * Number(time)
      this.hour = Math.floor((answerTime / 3600) % 24)
      this.minute = Math.floor((answerTime / 60) % 60)
      this.second = Math.floor(answerTime % 60)


      var self = this
      clearInterval(this.promiseTimer)
      this.promiseTimer = setInterval(function () {

        if (self.hour === 0) {
          if (self.minute !== 0 && self.second === 0) {
            self.second = 59
            self.minute -= 1
          } else if (self.minute === 0 && self.second === 0) {
            self.second = 0
            self.$emit('countDowmEnd', true)
            clearInterval(self.promiseTimer)
          } else {
            self.second -= 1
          }
        } else {
          if (self.minute !== 0 && self.second === 0) {
            self.second = 59
            self.minute -= 1
          } else if (self.minute === 0 && self.second === 0) {
            self.hour -= 1
            self.minute = 59
            self.second = 59
          } else {
            self.second -= 1
          }

        }
        // 强制交卷
        if (self.minute == 0 && self.second == 0 && self.hour == 0) {
          let totalMinutes = self.timeToMinutes(0, 0, 0);
          updateEndTime({
            endTime: totalMinutes,
            taskId: self.form.id
          }).then((res) => {
            self.livrerShow = true;
          })
          // this.doHandler();
        }
      }, 1000)
    },
    formatNum(num) {
      return num < 10 ? '0' + num : '' + num
    },

    // 答题卡样式
    cardItemClass(answered, id, answer) {
      if (id === this.cardItem.id) {
        return 'primary'
      }
      if (id === this.cardItem.quId) {
        return 'warning'
      }

      if (answered || answer != '') {
        return 'success'
      }

      if (!answered || answer == '') {
        return 'info'
      }
    },

    /**
     * 统计有多少题没答的
     * @returns {number}
     */
    countNotAnswered() {
      let notAnswered = 0;
      this.paperData.radioList.forEach(function (item) {
        if (!item.answered) {
          notAnswered += 1
        }
      })

      this.paperData.multiList.forEach(function (item) {
        if (!item.answered) {
          notAnswered += 1
        }
      })

      this.paperData.judgeList.forEach(function (item) {
        if (!item.answered) {
          notAnswered += 1
        }
      })

      return notAnswered
    },

    /**
     * 下一题
     */
    handNext() {
      const index = this.cardItem.sort + 1
      this.handSave(this.allItem[index])
    },

    /**
     * 上一题
     */
    handPrevious() {
      const index = this.cardItem.sort - 1
      this.handSave(this.allItem[index])
    },

    doHandler() {
      this.handleText = '正在交卷，请等待...'
      this.loading = true
      this.form.eduUserExamDetailList = this.eduUserExamDetailLists
      this.form.userId = this.userInfo.user_id
      this.form.paperId = this.cardItem.paperId
      this.form.examId = this.examId
      this.form.answerTime = this.paperData.answerTime
      this.form.endTime = this.formatDate(new Date())
      this.form.examStatus = '1';
      this.form.examType = '1';
      update(this.form).then(() => {
        this.$message({
          message: '试卷提交成功！',
          type: 'success'
        })
        // 交卷成功修改培训状态为已完成
        if (this.trainingId) {
          trainingTaskUseStatusUpdate({id: this.trainingId, status: 4})
        }
        this.$router.$avueRouter.closeTag();
        this.$router.back();
      })
    },
    formatDate(date) {
      let year = date.getFullYear().toString();
      let month = (date.getMonth() + 1).toString().padStart(2, '0');
      let day = date.getDate().toString().padStart(2, '0');
      let hours = date.getHours().toString().padStart(2, '0');
      let minutes = date.getMinutes().toString().padStart(2, '0');
      let seconds = date.getSeconds().toString().padStart(2, '0');

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    // 交卷操作
    handHandExam() {
      let that = this
      // 交卷保存答案
      that.handSave(that.cardItem, function () {
        const notAnswered = that.countNotAnswered()

        let msg = '确认要交卷吗？'

        if (notAnswered > 0) {
          msg = '您还有' + notAnswered + '题未作答，确认要交卷吗?'
        }

        that.$confirm(msg, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          that.doHandler()
        }).catch(() => {
          that.$message({
            type: 'info',
            message: '交卷已取消，您可以继续作答！'
          })
        })
      })
    },

    //保存答案
    handSave(item, callback) {
      // 存储上一个题目
      if (!this.lastQuestion) {
        this.lastQuestion = this.allItem[0]
      }
      if (item.id === this.allItem[0].id) {
        this.showPrevious = false
      } else {
        this.showPrevious = true
      }

      // 最后一个索引
      const last = this.allItem.length - 1

      if (item.id === this.allItem[last].id) {
        this.showNext = false
      } else {
        this.showNext = true
      }

      const answers = this.multiValue
      if (this.radioValue !== '') {
        answers.push(this.radioValue)
      }
      // 构建跳转前的题目
      let detail = {
        paperId: this.lastQuestion.paperId,
        questionId: this.lastQuestion.questionId,
        answerOption: this.$func.join(answers)
      };
      let hasDetail = false;
      for (let i = 0; i < this.eduUserExamDetailLists.length; i++) {
        // 如果列表中存在改题目， 则替换掉
        if (this.eduUserExamDetailLists[i].paperId === detail.paperId && this.eduUserExamDetailLists[i].questionId === detail.questionId) {
          this.eduUserExamDetailLists[i] = detail
          hasDetail = true
        }
      }
      if (!hasDetail) {
        this.eduUserExamDetailLists.push(detail);
      }
      this.lastQuestion = item
      getAnswers(item.questionId, item.paperId).then(() => {
        // 必须选择一个值
        if (answers.length > 0) {
          // 加入已答列表
          this.cardItem.answered = true
        }

        // 最后一个动作，交卷
        if (callback) {
          callback()
        }

        // 查找详情
        this.fetchQuData(item)
      })
    },

    // 试卷详情
    fetchQuData(item) {
      // 获得详情
      this.cardItem = item
      if (!item.questionId || !item.paperId) {
        return;
      }
      getAnswers(item.questionId, item.paperId).then(res => {
        this.quData = res.data.data;
        if (this.type == 'view') {
          if (this.cardItem.answer != '') {
            if ((this.cardItem.topicType == 1 || this.cardItem.topicType == 3)) {
              this.radioValue = this.cardItem.answer
            }
            if (this.cardItem.topicType == 2) {
              this.multiValue = this.cardItem.answer.split(",");
            }
          } else {
            this.radioValue = '';
            this.multiValue = []
          }
        } else {
          this.radioValue = '';
          this.multiValue = [];
          if (this.cardItem.answer != '') {
            if ((this.cardItem.topicType == 1 || this.cardItem.topicType == 3)) {
              this.radioValue = this.cardItem.answer
            }
            if (this.cardItem.topicType == 2) {
              this.multiValue = this.cardItem.answer.split(",");
            }
          } else {
            // 填充该题目的答案
            this.eduUserExamDetailLists.filter(e => {
              return e.paperId === this.cardItem.paperId && e.questionId === this.cardItem.questionId
            }).forEach(e => {
              if ((this.cardItem.topicType == 1 || this.cardItem.topicType == 3)) {
                this.radioValue = e.answerOption
              }
              if (this.cardItem.topicType == 2) {
                this.multiValue.push(...this.$func.split(e.answerOption))
              }
            })
          }
        }
        // this.quData.answerList.forEach((item) => {
        //   if ((this.quData.quType === 1 || this.quData.quType === 3) && item.checked) {
        //     this.radioValue = item.id
        //   }
        //   if (this.quData.quType === 2 && item.checked) {
        //     this.multiValue.push(item.id)
        //   }
        // })
      })
    },
    // 试卷详情
    fetchData(id) {
      getExamPaper(id, this.form.id).then(res => {
        // 试卷内容
        this.paperData = res.data.data;

        // 对试卷题目进行排序
        // 获得第一题内容
        let radioSort = -1;
        let multiSort = -1;
        let judgeSort = -1;
        if (this.paperData.radioList && this.paperData.radioList.length > 0) {
          radioSort = this.paperData.radioList[0].sort
        }
        if (this.paperData.multiList && this.paperData.multiList.length > 0) {
          multiSort = this.paperData.multiList[0].sort
        }
        if (this.paperData.judgeList && this.paperData.judgeList.length > 0) {
          judgeSort = this.paperData.judgeList[0].sort
        }
        this.paperSortData.forEach(e => {
          if (e.name === 'radioList') {
            if (radioSort == 0) {
              this.cardItem = this.paperData.radioList[0]
            }
            e.sort = radioSort
          } else if (e.name === 'multiList') {
            if (multiSort == 0) {
              this.cardItem = this.paperData.multiList[0]
            }
            e.sort = multiSort
          } else if (e.name === 'judgeList') {
            if (judgeSort == 0) {
              this.cardItem = this.paperData.judgeList[0]
            }
            e.sort = judgeSort
          }
        })
        // 排序
        this.paperSortData.sort((a, b) => a.sort - b.sort)
        const that = this

        this.paperData.radioList.forEach(function (item) {
          if (item.answer != '') {
            item.answered = true;
          }
          that.allItem.push(item)
        })

        this.paperData.multiList.forEach(function (item) {
          if (item.answer != '') {
            item.answered = true;
          }
          that.allItem.push(item)
        })

        this.paperData.judgeList.forEach(function (item) {
          if (item.answer != '') {
            item.answered = true;
          }
          that.allItem.push(item)
        })
        that.allItem.sort((a, b) => a.sort - b.sort)
        this.allItem.forEach((item) => {
          if (item.answer != '') {
            this.eduUserExamDetailLists.push({
              paperId: item.paperId,
              questionId: item.questionId,
              answerOption: item.answer
            })
          }
        })
        // 当前选定
        this.fetchQuData(this.cardItem)
        // if(this.paperData == 0){
        //   this.livrerShow = true;
        // }
        // 倒计时
        if (this.type == 'edit') {
          this.countdown()
        }
      })
    }

  }
}
</script>

<style scoped lang="scss">

.qu-content div {
  line-height: 30px;
}

.el-checkbox-group label, .el-radio-group label {
  width: calc(100% - 30px);
}

.el-checkbox + .el-checkbox {
  margin-left: 0px;
}

.el-button + .el-button {
  margin-left: 12px;
}

.card-title {
  background: #eee;
  line-height: 35px;
  text-align: center;
  font-size: 14px;
}

.card-line {
  padding-left: 10px
}

.card-line span {
  cursor: pointer;
  margin: 2px;
}

.el-radio, .el-checkbox {
  padding: 9px 20px 9px 10px;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  margin-bottom: 10px;
}

.is-checked {
  border: #409eff 1px solid;
}

.el-radio img, .el-checkbox img {
  max-width: 200px;
  max-height: 200px;
  border: #dcdfe6 1px dotted;
}

.el-checkbox__inner {
  display: none;
}

.el-radio__inner {
  display: none;
}

.el-checkbox__label {
  line-height: 30px;
}

.el-radio__label {
  line-height: 30px;
}

</style>
