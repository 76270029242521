var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 24 } },
        [
          _vm.type == "edit"
            ? _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c("el-card", { staticStyle: { "margin-bottom": "10px" } }, [
                    _vm._v("\n        距离考试结束还有："),
                    _c("span", { staticStyle: { color: "#ff0000" } }, [
                      _vm._v(
                        _vm._s(_vm.hour) +
                          "小时" +
                          _vm._s(_vm.minute) +
                          "分钟" +
                          _vm._s(_vm.second) +
                          "秒"
                      ),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-col",
            {
              staticStyle: { "margin-bottom": "10px" },
              attrs: { span: 6, xs: 24 },
            },
            [
              _c(
                "el-card",
                { staticStyle: { height: "calc(100vh - 162px)" } },
                [
                  _c("p", { staticClass: "card-title" }, [_vm._v("答题卡")]),
                  _c(
                    "el-row",
                    {
                      staticClass: "card-line",
                      staticStyle: { "padding-left": "10px" },
                      attrs: { gutter: 24 },
                    },
                    [
                      _c("el-tag", { attrs: { type: "info" } }, [
                        _vm._v("未作答"),
                      ]),
                      _c("el-tag", { attrs: { type: "success" } }, [
                        _vm._v("已作答"),
                      ]),
                    ],
                    1
                  ),
                  _vm._l(_vm.paperSortData, function (sortItem, sortIndex) {
                    return _c("div", { key: sortIndex }, [
                      sortItem.sort != -1 &&
                      _vm.paperData[sortItem.name] !== undefined &&
                      _vm.paperData[sortItem.name].length > 0
                        ? _c(
                            "div",
                            [
                              _c("p", { staticClass: "card-title" }, [
                                _vm._v(_vm._s(sortItem.title)),
                              ]),
                              _c(
                                "el-row",
                                {
                                  staticClass: "card-line",
                                  attrs: { gutter: 24 },
                                },
                                _vm._l(
                                  _vm.paperData[sortItem.name],
                                  function (item) {
                                    return _c(
                                      "el-tag",
                                      {
                                        key: item.label,
                                        attrs: {
                                          type: _vm.cardItemClass(
                                            item.answered,
                                            item.id,
                                            item.answer
                                          ),
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handSave(item)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(item.sort + 1) +
                                            "\n              "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ])
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 18, xs: 24 } },
            [
              _c("el-card", { staticClass: "qu-content" }, [
                _vm.quData.content
                  ? _c("p", [
                      _vm._v(
                        _vm._s(_vm.quData.sort + 1) +
                          "." +
                          _vm._s(_vm.quData.content)
                      ),
                    ])
                  : _vm._e(),
                _vm.quData.quType === "1" || _vm.quData.quType === "3"
                  ? _c(
                      "div",
                      [
                        _c(
                          "el-radio-group",
                          {
                            attrs: { disabled: _vm.type == "view" },
                            model: {
                              value: _vm.radioValue,
                              callback: function ($$v) {
                                _vm.radioValue = $$v
                              },
                              expression: "radioValue",
                            },
                          },
                          _vm._l(_vm.quData.answerList, function (item, index) {
                            return _c(
                              "el-radio",
                              { key: index, attrs: { label: item.id } },
                              [
                                _vm._v(
                                  _vm._s(item.answerOption) +
                                    "." +
                                    _vm._s(item.answerOptionValue) +
                                    "\n            "
                                ),
                              ]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.quData.quType === "2"
                  ? _c(
                      "div",
                      [
                        _c(
                          "el-checkbox-group",
                          {
                            attrs: { disabled: _vm.type == "view" },
                            model: {
                              value: _vm.multiValue,
                              callback: function ($$v) {
                                _vm.multiValue = $$v
                              },
                              expression: "multiValue",
                            },
                          },
                          _vm._l(_vm.quData.answerList, function (item, index) {
                            return _c(
                              "el-checkbox",
                              { key: index, attrs: { label: item.id } },
                              [
                                _vm._v(
                                  _vm._s(item.answerOption) +
                                    "." +
                                    _vm._s(item.answerOptionValue) +
                                    "\n            "
                                ),
                              ]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _vm.type == "view"
                ? _c(
                    "el-card",
                    { staticStyle: { "margin-top": "20px", color: "#f74e57" } },
                    [
                      _c("p", [
                        _vm._v("正确答案：" + _vm._s(_vm.quData.correctAnswer)),
                      ]),
                      _c("p", [
                        _vm._v("答案解析：" + _vm._s(_vm.quData.analysis)),
                      ]),
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                { staticStyle: { "margin-top": "20px" } },
                [
                  _vm.showPrevious
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            icon: "el-icon-arrow-left",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handPrevious()
                            },
                          },
                        },
                        [_vm._v("上一题\n        ")]
                      )
                    : _vm._e(),
                  _vm.showNext
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "warning" },
                          on: {
                            click: function ($event) {
                              return _vm.handNext()
                            },
                          },
                        },
                        [
                          _vm._v("下一题"),
                          _c("i", {
                            staticClass: "el-icon-arrow-right el-icon--right",
                          }),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.type == "edit"
        ? _c(
            "div",
            { staticClass: "fixed_button" },
            [
              _c(
                "el-button",
                {
                  staticStyle: { float: "right", "margin-top": "-10px" },
                  attrs: {
                    type: "primary",
                    icon: "el-icon-plus",
                    loading: _vm.loading,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handHandExam()
                    },
                  },
                },
                [_vm._v("\n      " + _vm._s(_vm.handleText) + "\n    ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提示",
            visible: _vm.livrerShow,
            "show-close": false,
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            center: true,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.livrerShow = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                "text-align": "center",
                "font-size": "26px",
                "font-weight": "bold",
                color: "#333333",
              },
            },
            [_vm._v("考试时间已到，请交卷")]
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handHandExam } },
                [_vm._v("交 卷")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }