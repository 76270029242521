<!--
 * @LastEditors: absorbedyy@163.com
 * @LastEditTime: 2024-06-26 09:49:54
-->
<template>
  <basic-container v-loading="dialogLoading" class="qmDialog">
    <head-layout class="margin-b-10" head-title="" :head-btn-options="headOptions"></head-layout>
    <div class="flex-container form-box">
      <avue-form :option="options" ref="addForm" v-model="form" @submit="treeNodeSave">
        <template slot="companyName">
          <el-autocomplete placeholder="请输入公司名称" :disabled="pageType == 'view'" v-model="form.companyName"
                           :fetch-suggestions="querySearch" :trigger-on-focus="false" @select="handleSelect"
                           maxlength="50">
          </el-autocomplete>
        </template>
        <template slot="companyCode">
          <el-autocomplete placeholder="请输入统一社会信用代码" :disabled="pageType == 'view' || isEdit"
                           v-model="form.companyCode"
                           :fetch-suggestions="queryCodeSearch" :trigger-on-focus="false" @select="handleSelect"
                           maxlength="50">
          </el-autocomplete>
        </template>
        <template slot="areaId">
          <el-cascader placeholder="请选择注册位置" :disabled="pageType == 'view' || isEdit" ref="prjAreaCascader"
                       filterable
                       style="width:100%" v-model="form.areaId" :options="areaDic"
                       @change="handleChangeArea"></el-cascader>
        </template>
        <template slot="isUsed" slot-scope="{row}">
          <el-switch :active-value="0" :inactive-value="1" v-model="form.isUsed" :inactive-text="form.isUsed == '1' ?'已离场' :''" :active-text="form.isUsed == '0'?'已入场':''"></el-switch>
        </template>
      </avue-form>
      <div
        v-if="form.id"
        style=" width: 150px;height: 150px; text-align: center; z-index: 1; ">
        <el-image
          :src="qrcodeSrc"
          style="width: 100px; height: 100px"
        ></el-image>
        <el-button size="mini" @click.native="downloadQrcode"
        >下载
        </el-button
        >
      </div>
    </div>
    <div class="shuttleBackBox">
      <el-tabs v-model="activeName" @tab-click="handleClick()">
        <el-tab-pane label="企业证照" name="qyzz"></el-tab-pane>
        <el-tab-pane label="人员清单" name="ryqd"></el-tab-pane>
        <el-tab-pane label="项目记录" name="xmjl"></el-tab-pane>
        <el-tab-pane label="奖惩记录" name="jcjl"></el-tab-pane>
        <el-tab-pane label="黑名单记录" name="hmdjl"></el-tab-pane>
      </el-tabs>
      <head-layout v-if="activeName == 'qyzz'" class="margin-b-10" head-title="企业证照"
                   :head-btn-options="headBtnOptions" @head-save="headSave"></head-layout>
      <avue-form class="form-box" :upload-delete="uploadDelete" v-if="activeName == 'qyzz'" :upload-after="uploadAfter"
                 :option="formOption" v-model="fileForm" :upload-error="uploadSized"></avue-form>
      <avue-crud  size="mini"  @search-change="searchChange"  @search-reset="searchReset" :search.sync="search" @current-change="currentChange"
                 @size-change="sizeChange" :page.sync="page" v-if="activeName != 'qyzz' && showPage"
                 :option="{...option[activeName],...optionDict}" :data="tableData">
        <template slot="startAgeSearch">
          <div class="number-range">
            <avue-input-number  v-model="search.startProp" placeholder="开始年龄"
                               clearable :controls="false"></avue-input-number>-
            <avue-input-number v-model="search.endProp" placeholder="结束年龄"
                               clearable :controls="false"></avue-input-number>
          </div>
        </template>
        <template slot="baseCode" slot-scope="{ row }">
          <el-image :preview-src-list="[formatter(row)]" style="padding-top:10px;width: 40px;"
                    :src="formatter(row)"></el-image>
        </template>
      </avue-crud>
    </div>
    <!-- 加入项目扫描二维码弹框 -->
    <qrcodeDialog ref="qrcodeDialog"></qrcodeDialog>
  </basic-container>
</template>

<script>
import {
  prjthirdcompanySubmit, prjthirdcompanyDetail
} from "@/api/safe/itemList";
import {
  getList
} from "@/api/safe/thirdcompany";
import {getLazyTree, getAreaData1} from "@/api/base/region";
import qrcodeDialog from "@/views/projectSettings/regionManage/qrcode.vue";
import OpenBlackDialog from "@/views/business/safe/itemList/openBlackDialog.vue";
import organization from "@/views/business/safe/itemList/organization";
import Template from "@/views/message/template/list.vue";

export default {
  components: {
    Template,
    OpenBlackDialog,
    qrcodeDialog
  },
  mixins: [organization],
  data() {
    return {
      qrcodeSrc: "", // 二维码图片src
      showDialog: false,
      dialogLoading: false,
      treeReadOnlyFlag: false,
      form: {},
      title: '',
      isEdit: false,
      searchData: [],
      pageType: '',
      areaDic: [], // 区域位置
    }

  },
  computed: {
    headOptions() {
      let result = [];
      result.push(
        {
          label: '返回',
          handle:()=>{
            this.closeDialog()
          },
          type: "button",
          btnOptType: "default",
        },
        {
          label: '保存',
          type: "button",
          handle: (row) => {
            this.saveBack=false
            this.handleTreeNodeSave()
          },
          btnOptType: 'save',
        },
        {
          label: '保存返回',
          handle:()=>{
            this.saveBack=true
            this.handleTreeNodeSave()
          },
          type: "button",
          btnOptType: "saveBack",
        },
      )
      return result
    },
    options() {
      return {
        menuBtn: false,
        size: 'small',
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 140,
        column: [{
          label: "公司名称",
          prop: "companyName",
          formslot: true,
          rules: [{
            required: true,
            message: "请输入公司名称",
            trigger: "blur"
          }, {max: 30, message: '公司名称不能超过30个字符', trigger: 'blur'}]

        },
          {
            label: "统一社会信用代码",
            prop: "companyCode",
            formslot: true,
            rules: [{
              required: true,
              message: "请输入统一社会信用代码",
              trigger: "blur"
            }, {max: 30, message: '统一社会信用代码不能超过30个字符', trigger: 'blur'}]
          },
          {
            label: "法定代表人",
            prop: "legalRep",
            disabled: this.isEdit || this.pageType == 'view',
            rules: [{
              required: false,
              message: "请输入法定代表人",
              trigger: "blur"
            }, {max: 10, message: '法定代表人不能超过10个字符', trigger: 'blur'}]
          },
          {
            label: "成立日期",
            prop: "curDate",
            type: "date",
            disabled: this.isEdit || this.pageType == 'view',
            format: "yyyy-MM-dd",
            valueFormat: "yyyy-MM-dd",
            "picker-options": {
              disabledDate: (time) => {
                return time.getTime() > Date.now() - 3600 * 1000 * 24;
              },
            },
            rules: [{
              required: true,
              message: "请选择成立日期",
              trigger: "blur"
            }]
          },
          {
            label: "注册位置",
            prop: "areaId",
            formslot: true,
            type: 'cascader',
            dicData: this.areaDic,
            disabled: this.isEdit || this.pageType == 'view',
            rules: [{
              required: false,
              message: "请选择注册位置",
              trigger: "change"
            }]
          },
          {
            label: "详细位置",
            prop: "regLocation",
            disabled: this.isEdit || this.pageType == 'view',
            rules: [{
              required: false,
              message: "请输入详细位置",
              trigger: "blur"
            }, {max: 255, message: '详细位置不能超过255个字符', trigger: 'blur'}]
          },{
            label: "组织别名",
            prop: "companyAlisaName",
            rules: [{
              required: false,
              message: "请输入组织别名",
              trigger: "blur"
            }, {max: 255, message: '组织别名不能超过255个字符', trigger: 'blur'}]
          },{
            label: "单位类型",
            prop: "companyType",
            rules: [{
              required: false,
              message: "请输入单位类型",
              trigger: "blur"
            }],
            type: 'select',
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=third_company_type",
          },
          {
            label: '状态',
            prop: 'isUsed',
            disabled: this.pageType == 'view',
            formslot: true
          },
        ],
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    // 下载二维码
    downloadQrcode() {
      this.$refs.qrcodeDialog.init(
        this.qrcodeSrc,
        this.form.companyName,
        this.form.id
      );
    },
    handleClick(id) {
      if(!this.form.id)return
      if (this.activeName != 'qyzz') {
        this.showPage=false
        this.$nextTick(()=>{
          this.showPage=true
          this.getTable()
        })
      } else {
        this.getFile(id)
      }
    },
    //   项目位置
    getAreaData(parentCode) {
      getAreaData1(parentCode, this.areaDic);
    },
    // 区域位置获取
    handleChangeArea(value) {
      const regionList = this.$refs['prjAreaCascader'].getCheckedNodes()[0].pathLabels
      if (regionList && regionList.length != 0) {
        this.form.areaName = regionList.join(',')
      }
    },
    async getDetail() {
      await prjthirdcompanyDetail({id:this.form.id}).then(res => {
        let data=res.data.data
        this.form = {
          ...data,
          areaId: data.areaId ? data.areaId.split(',') : [],
        }
      })
      this.qrcodeSrc = "data:image/png;base64," + this.form.baseCode?.codeUrl || ''
      this.handleClick()
    },
    async init(type, id) {
      this.getAreaData()
      this.form = {
        id:this.$route.query.id
      }
      let prjId=this.$route.query.prjId
      this.activeName = 'qyzz'
      this.showDialog = true
      this.treeReadOnlyFlag = false;
      this.pageType = this.$route.query.type
      if (this.pageType == 'edit') {
        this.title = '编辑相关方公司';
         this.getDetail()
      } else if (this.pageType == 'view') {
        this.title = '查看相关方公司'
         this.getDetail()
        this.isEdit = true
      } else {
        this.title = '新增相关方公司'
        this.form = {
          prjId: prjId,
          isUsed: 0,
          areaId: [],
        }
        this.qrcodeSrc = ''
      }

    },
    //输入查询
    querySearch(queryString, cb) {
      let result = []
      this.isEdit = false
      if (queryString == '') {
        cb(result)
      } else {
        Promise.all([this.getListInfo(queryString, 'companyName')]).then(() => {
          cb(this.searchData);
        })
        // 调用 callback 返回建议列表的数据
      }
    },
    queryCodeSearch(queryString, cb) {
      this.isEdit = false
      let result = []
      if (queryString == '') {
        cb(result)
      } else {
        Promise.all([this.getListInfo(queryString, 'companyCode')]).then(() => {
          cb(this.searchData);
        })
        // 调用 callback 返回建议列表的数据
      }
    },
    getListInfo(queryString, type) {
      this.query = {
        companyCode: queryString
      }
      return new Promise((r) => {
        getList(1, 20, this.query).then((res) => {
          let data = res.data.data.records
          let hasUseData = []
          if (data.length) {
            hasUseData =  data.map((item) => {
                return {
                  ...item,
                  value: type == 'companyCode' ? item.companyCode : item.companyName
                }
            })
            this.searchData = hasUseData
          } else {
            this.searchData = []
          }
          r()
        })
      })
    },
    handleSelect(item) {
      this.form.companyName = item.companyName
      this.form.companyAlisaName = item.companyAlisaName
      this.form.companyCode = item.companyCode
      this.form.legalRep = item.legalRep
      this.form.regLocation = item.regLocation
      this.form.curDate = item.curDate
      this.form.areaId = item.areaId ? item.areaId.split(',') : []
      this.isEdit = true
      this.handleClick(item.id)
    },
    handleTreeNodeSave() {
      this.$refs.addForm.submit();
    },
    saveData() {
      let form ={...this.form}
      form.areaId = this.form.areaId.length ? this.form.areaId.join(',') : ''
      this.dialogLoading = true
      prjthirdcompanySubmit(form).then(res => {
        this.treeReadOnlyFlag = true;
        if (res.data.code == 200) {
          this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
        } else {
          this.$message.success(res.data.msg);
        }
        if(this.saveBack){
          this.closeDialog()
        }
        this.$emit('callback')
        this.form = {
          id:res.data.data
        }
        this.isEdit = false
        this.getDetail()
        this.dialogLoading = false;
      }).catch(err => {
      }).finally(() => {
        this.dialogLoading = false
      })
    },
    treeNodeSave() {
      this.$refs.addForm.validate((valid, done) => {
        if (valid) {
          this.saveData()
        }
      })
    },
  }
}

</script>
<style lang="scss">
.qmDialog {
  .el-autocomplete {
    width: 100%
  }
  .number-range{
    display: flex;
    justify-content: space-between;
    border: 1px solid #DCDFE6;
    .el-input__inner{
      border: 0 !important;
    }
  }
  .form-box {
    background: #ffffff;
    padding: 12px;
  }

  .el-upload--picture-card {
    width: 100px;
    height: 100px;
    line-height: 100px;
  }

  .el-upload-list--picture-card {
    .el-upload-list__item {
      width: 100px;
      height: 100px;
    }
  }
  .shuttleBackBox {
    width: 100%;
    height: 100%;
    background-color: #fff;

    .el-tabs {
      width: 100%;
    }
  }
  .avue-crud__search {
    .el-form-item__label {
      display: none;
    }

    .el-form-item__content {
      margin-left: 0 !important;
    }
    .reset-refresh{
      font-size: 12px;
    }
    .avue-form__menu {
      .el-button {
        margin: 0 !important;
      }
    }
  }
}

</style>
